import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesRei: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide '} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Rei Ayanami guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_rei.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Rei Ayanami Guide & Review</h1>
          <h2>
            A guide & review for Rei Ayanami in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>31/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Rei Ayanami" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="rei-ayanami" enablePopover />
              </div>
              <p>
                Scarlet is everyone's favorite and all, but let us introduce you
                to Rei Ayanami, a tenderhearted heroine who is here to steal
                Scarlet's job. She can flatten the planet with her tactical
                nuke, while dealing some extra damage on the side through basic
                attacks which is also higher than Scarlet's. Rei can also
                empower other Fire Code allies, granting them ATK and ATK DMG
                from time to time, as well as providing Individual Shield.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Rei has a balanced kit that leans more toward the supportive
                side rather than the offensive. You can say she is a Support
                disguised as an Attacker. She offers ATK buff to all Fire Code
                allies upon entering Full Burst, which is neat. Since this is
                based on caster's ATK, and Rei is an Attacker herself, there is
                no conversion cost unless you intentionally leave her
                underdeveloped. Her Burst Skill also greatly enhances the ATK
                DMG of all Fire Code allies for 10s.
              </p>
              <p>
                While all seems gold, there are some slight shortcomings that
                Rei suffers from that limit her compatibility in Raids. It is
                likely by design to prevent her from becoming too versatile. The
                game achieves this by ensuring that she only synergizes well
                with one kind of DPS: those with Fire Code. While this may look
                like a bad thing since Rei's supporting ability is crucial to
                what we have rated her, the benefit is that the current roster
                of Fire DPS is actually pretty insane! Modernia and Asuka are
                the number one choices to pair alongside Rei. Alice, not so
                much, but that is because their playstyle is different (Rei's
                ATK DMG comes during her own Burst Rotation, and Alice does not
                deal much damage in that period).
              </p>
              <p>
                Now that we have talked about her kit in general, let us talk
                about usability. In Raids, Rei's presence is able to push the
                already broken Asuka and Modernia further. The trio can
                definitely be considered as either the best or the second best
                combination against Wind enemies, and this is even more true if
                the boss is weak to Pierce. Not only that, this team also works
                quite well against non-Wind bosses too! The reason is that Asuka
                complements Rei and Modernia very well, and Rei also complements
                both very well. They have a reciprocal relationship. Rei might
                be a niche support unit that only works with certain Fire DPS,
                but in exchange her buffs are incredibly strong, and the allies
                she buffs are at the top of the food chain.
              </p>
              <p>
                Additionally, in Campaign, her wipe, which is just as strong as
                OG Scarlet's, will be able to help you pass difficult wall
                stages requiring instant AoE. Modernia, Alice, and Asuka, who
                are also staples for Campaign, will also benefit from her
                presence. And finally, if that wasn't enough, thanks to her
                wipe, you can also formulate new teams with her in PvP!
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                Certainly. She has a solid role against Wind bosses and makes
                running Triple Fire comp with Asuka and Modernia more realistic,
                even against other elements. In fact, her buffs are reliable
                since they do not rely on the existence of a persistent core
                like Asuka's. Rei will also certainly bring QoL improvements to
                your campaign pushing and allow you to experience a wider
                variety of teams in Raids and PvP. She is limited too, so do not
                miss out on the opportunity to cash in!
              </p>
              <p>
                As for Gold Ticket usage, which a lot of people have been
                asking, only use Gold Ticket(s) if you have at least 400
                Mileage. Always keep 200 in store for the upcoming anniversary
                in 2 months!
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/rei_guide_1.webp"
                alt="Kit"
              />
              <p>
                Rei is an Attacker unit with lots of supportive buffs making her
                more of a Support/DPS hybrid. While she does deal more
                single-target damage than Scarlet, her role is more of a support
                & semi-DPS in Raids. In Campaign and PvP, she can be a major DPS
                thanks to her huge AoE nuke.
              </p>
              <p>
                Rei Ayanami has a decent basic attack multiplier to begin with,
                and that's thanks to her wielding an MG. However, her kit does
                not have many buffs, especially those reserved for herself. She
                does have that situational Elemental DMG on top of an extra
                damage factor, but the value for the latter is infinitesimal.
                You can even assume that her multiplier is as raw as it can
                be—basic attacks + bonus from every 100 shots. Still, meager
                amount of buffs means that she should scale pretty well with
                external buffs.
              </p>
              <Alert variant="primary">
                <p>
                  As with Alice and Modernia, Rei Ayanami can efficiently
                  utilize Asuka's Core DMG buff because she has perfect accuracy
                  all the time.
                </p>
              </Alert>
              <h5>Let Her Cook</h5>
              <blockquote>
                <p>■ Activates after 100 normal attack(s). Affects self.</p>
                <p>Damage as strong element ▲ 30.23% for 3 sec.</p>
                <p>
                  ■ Activates after landing 100 normal attack(s). Affects the
                  enemy within attack range nearest to the crosshair.
                </p>
                <p>Deals 112.37% of final ATK as damage.</p>
              </blockquote>
              <p>
                Rei's Skill 1 grants her a nearly permanent Elemental DMG buff
                that temporarily deactivates whenever she reloads. That is
                because it lasts for 3 seconds, and her reload takes 2.5 + 0.367
                seconds. In other words, every time she reloads, the first 100
                hits thereafter would be raw. This means, without any Max Ammo
                buffs, the Elemental DMG only applies to 66.7% of her total
                shots.
              </p>
              <Alert variant="primary">
                <p>
                  Reload Speed buffers and Max Ammo OL are Rei's best friends.
                  Make sure to stock up on as much Max Ammo as possible as you
                  would with other MGs.
                </p>
              </Alert>
              <p>
                Rei also deals an additional amount of skill damage every 100
                shots, but this value is fairly small. It does contribute to her
                DPS, but it cannot core hit. It is around 20.1% of the amount of
                DMG she would deal through 100 basic attacks, assuming there is
                no core.
              </p>
              <h5>Tasting the Food</h5>
              <blockquote>
                <p>■ Activates at the start of the battle. Affects self.</p>
                <p>Damage dealt to Shield 700.5% continuously</p>
                <p>
                  (It only affects the damage dealt to the shield, not to the
                  Rapture itself)
                </p>
                <p>
                  ■ Activates when entering Burst stage 3. Affects all Fire Code
                  allies.
                </p>
                <p>ATK ▲ 25.03% of caster's ATK for 10 sec.</p>
              </blockquote>
              <p>
                Just like Asuka, Rei can also deal extra damage to Shield. As we
                explained in Asuka's review, this gimmick is useful for breaking
                barriers/shields in Campaign and against particular bosses. For
                Raids, generally, you want to split your team resources such
                that no team is bottlenecked. However, whether it is ideal to
                separate Asuka and Rei for minmaxing in the next Raid is
                something we are to find out.
              </p>
              <p>
                Rei also increases the ATK of Fire Code allies upon entering
                Burst Stage 3 for 10s. The value is based on caster's ATK, but
                since Rei is an Attacker, there is no conversion cost happening
                unless you leave her underdeveloped intentionally. As usual, ATK
                buffs such as this one are more impactful toward units that do
                not have too much ATK themselves. Modernia is a good example,
                having less than 30% ATK in her kit. Rei also benefits from this
                very ability too, but since Rei does not have high enough base
                multiplier as delinated in the previous section, then her output
                will still be minuscule.
              </p>
              <Alert variant="primary">
                <p>
                  Buffs triggered upon entering Burst Stage 3 take place before
                  the B3 Burst Skill is used. This means damaging abilities like
                  Power's Burst Skill can benefit from this increased ATK.
                </p>
              </Alert>
              <h5>Serving the Food Unfairly</h5>
              <blockquote>
                <p>■ Affects all Fire Code allies.</p>
                <p>
                  Creates a Shield equal to 13.44% of the caster's final Max HP
                  for 10 sec.
                </p>
                <p>Attack damage ▲ 48.02% for 10 sec.</p>
                <p>■ Affects all enemies.</p>
                <p>Deals 990.2% of final ATK as damage.</p>
              </blockquote>
              <p>
                Rei's Burst Skill constructs a temporary Individual Shield and
                momentarily increases the ATK DMG of Fire Code allies for 10s.
                While this looks impressive on paper, do note that the
                enhancement is applied during Rei's Burst Rotation, which means
                Nikke(s) who do not have a stable damage output cannot make full
                use of this ability. One example is Alice, whose damage is
                concentrated in her Burst Skill. On the other hand, Nikke(s)
                like Modernia and partially Asuka should have no problem
                utilizing this buff.
              </p>
              <Alert variant="primary">
                <p>
                  Rei's Shield only applies to Fire Code allies, so it cannot be
                  used to protect the whole team against an AoE attack, unless
                  everyone belongs to the Fire element. The Shield, however, can
                  activate the second component of Asuka's S2, giving her
                  Elemental DMG. Even so, this only happens during Rei's Burst
                  Rotation, whereas Asuka's peak firepower is during her own
                  Burst. For your information, Asuka deals 40% of her damage
                  outside her Burst.
                </p>
              </Alert>
              <p>
                Rei's Burst Skill also features a screen wipe which can
                eliminate multiple mobs and projectiles at once. Impactful
                particularly in Campaign where she can compensate her lack of
                ATK buff with Liter, Crown, or Red Hood B1. This will become
                more evident against Wind mobs as her damage will multiply
                against them. Dealing with bosses that often launch projectiles
                or spawn mobs will never be this convenient.
              </p>
              <Alert variant="primary">
                <p>
                  Rei's ATK DMG is applied before the wipe, faithful to the
                  order displayed on the skill information.
                </p>
              </Alert>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Synergy with Alice, Asuka, and Modernia" />
              <ul>
                <li>
                  <strong>
                    <NikkeCharacter mode="inline" slug="alice" enablePopover />
                  </strong>{' '}
                  - Rei's synergy with Alice is not that good. That is because
                  Rei's ATK DMG comes from her Burst Skill, but Alice's DMG is
                  all in her Burst Skill. This means there is zero overlap
                  between Alice's peak DPS period and Rei's buff duration. The
                  ATK buff still applies, though.
                </li>
                <li>
                  <strong>
                    <NikkeCharacter
                      mode="inline"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </strong>{' '}
                  - Great but imperfect. Asuka already has too much ATK buff,
                  but more is always appreciated. Similar to Alice, Asuka's main
                  DPS window is during her own Burst, but unlike the bunny girl,
                  she still deals some damage outside (40%). Rei focuses on
                  boosting this "outside" damage and is amazing at that. But,
                  while this is fine, do note that Rei's Shield also activates
                  only during her own rotation, so without any other Individual
                  Shielder, Asuka will NOT enjoy her own Elemental DMG buff
                  during her main DPS window. That being said, Asuka also buffs
                  Rei's Core Damage, making their relationship mutualistic.
                </li>
                <li>
                  <strong>
                    <NikkeCharacter
                      mode="inline"
                      slug="modernia"
                      enablePopover
                    />
                  </strong>
                  - Excellent. Modernia is a stable damage dealer who does not
                  need to use Burst Skill. Her damage is consistent 24/7.
                  Because of that, she can fully utilize Rei's ATK DMG buff.
                  Modernia's kit also does not have a lot of ATK, so Rei's ATK
                  buff will mean a lot to her.
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Usage Analysis and Tier List" />
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Tier List
                  Adjustments
                </h6>
                <p>
                  With new units coming out, the tier list needs to be adjusted
                  again to prevent overcrowding in some ranks. We performed this
                  in advance of Rei's review release. Some units have been
                  promoted or demoted to highlight gaps between performance
                  better. Affected Tier List(s): Story, Boss. As usual,
                  interpret ratings in relation to other units in the same tier.
                </p>
              </blockquote>
              <h5>
                Campaign (LD) - <strong className="sss">SSS</strong> | Campaign
                (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                {' '}
                Rei's primary role in Campaign is AoE Nuke, so she functions
                similar to Scarlet, and in fact they have similar auto-attack
                damage. One advantage she has, though, is that she does not
                delete herself. On the contrary, Rei does not have as much ATK
                buff, so she is more prone to stat penalty, but Crown + Liter
                can help mitigate that. If you are looking for a decent MG DPS
                who can help you push through Chapter 19 and 20 (infested with
                hitcount Raptures), then Guillotine Rei can be a good
                alternative. Even outside these chapters, wipers in general are
                competent for handling a large number of mobs.
              </p>
              <p>
                She thrives in the presence of other Fire DPS since she is more
                of a support. She buffs the ATK and ATK DMG of Fire Code allies
                and shield them from otherwise fatal attacks. This will help
                combat stat penalty and make the team's overall DMG more
                tangible. If a new player is complaining about how hard it is to
                get Scarlet to help them push through the early stages, consider
                rolling Rei! In Low Deficit, Rei's lack of ATK buff will not
                matter much. She still suffers the same fate as Scarlet against
                super High Deficit—her nuke can be short of killing anything,
                but at least it is still better than nothing since it leaves all
                enemy units with barely any HP.
              </p>
              <h5>
                Boss (Solo) - <strong className="s">S</strong> | Boss (Adds) -{' '}
                <strong className="ss">SS</strong>
              </h5>
              <p>
                {' '}
                Average personal DPS, falling behind most meta DPS, but her
                buffs are priceless for certain Fire DPS (Asuka & Modernia).
                Wipe is handy for projectile & mob clearing, and she can help
                shatter shields faster so that your other Nikke(s) can continue
                inflicting damage. Shield is useful for protecting Fire Code
                allies from one-hit attacks (and reduce the frequency of going
                to cover, which leads to damage loss). She also has built-in
                Elemental DMG that slightly improves her damage toward Wind
                bosses.
              </p>
              <h5>
                PVP - <strong className="ss">SS</strong>
              </h5>
              <p>
                {' '}
                Her performance in PvP is solely dictated by her nuke. Amongst
                all of the B3 nukers, hers is one of the highest. Rei does not
                lose any buffs when revived by XAnne (Scarlet loses ATK buffs,
                etc). In fact, she gains ATK afterward. What hinders her is the
                fact that she wields an MG, which has low burst generation and
                also feeds into Scarlet & Jackal. You will need to offset the
                loss of speed with batteries, such as Jackal or even Rosanna
                (Sacrifice). Therefore, due to her easily counterable nature,
                Rei is suited to Attacking more than Defending, but is still a
                very strong choice for defense sacrifice strats due to the fact
                that she keeps her buff when revived.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Rei's OL improves her DPS but not her supportive capability
                (except from the increased base stats from higher level
                equipment). She does not have a jaw-dropping damage output, and
                she is not that meta-evolving. Exercise caution when making
                investments as the return may be smaller than you think. You may
                be unsatisfied. We recommend not spending too many rocks on her.
              </p>
              <p>
                She scales best with Max Ammo OL, just like any MG. You can
                alternatively aim for more ATK/ELE to ramp her DPS up. Her kit
                does not have a lot of ATK and not a single percent when S2 is
                inactive, making ATK a good generalist's choice. And finally,
                ELE is still the same powerful but situational buff.
              </p>
              <p>
                As usual, she is a decent Campaign and PvP candidate, and
                therefore CP padding is a valid tech. Aim for multiple lines, at
                the higher levels if possible, even if they do not contribute to
                her DPS at all. For example, if you get 3× Level 11 Lines, with
                the Line 3 being Max Ammo, you might want to consider stopping.
              </p>

              <ul>
                <li>
                  <strong>Essential:</strong> 3× Max Ammo
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Attack, 4× Elemental Damage, 1× Max
                  Ammo
                </li>
                <li>
                  <strong>Passable:</strong> -
                </li>
                <li>
                  <strong>Priority:</strong> High
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 4~10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    A self-steroid (personal DMG boost). You can upgrade it if
                    you have spare skill books or stop at 4 for a more economic
                    approach. Higher value when fighting Wind enemies. Normal
                    scaling.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 7~10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    This skill provides buffs for both herself and Fire Code
                    allies. ATK buff is nice and all, so we recommend getting it
                    to at least 7, but 10 would be the end goal. The scaling is
                    average, though, and the return may not be immediately felt.
                    It is a fairly small boost anyways.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 7~10</strong>
                </li>
                <ul>
                  <li>
                    {' '}
                    This skill works in both PvE and PvP. In Campaign, her main
                    duty is to fully wipe the screen clean. In High Deficit,
                    mobs are tanky, and usually a higher percentage is needed to
                    exterminate them. In this case, higher investments are
                    justifiable. The same thing also applies to PvP, but this
                    time, instead of stat penalty, you will be facing Max HP
                    boosts, Damage Reduction, etc.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="rei-ayanami" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                Bastion has the edge over Resilience on MGs. But, if you pair
                Rei with Reload Speed buffers like Crown or Privaty, then
                Resilience works just fine. As long as her reload is shorter
                than 0.6s (76% reload), she will not have to wind up.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvE Team Compositions" />

              <h5>Team #1: Bunny Power</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Ammo Support is crucial to some units in game, and the OG Bunny
                Duo of Blanc and Noir is the best way to achieve this. Noir's
                Max Ammo buffs suit all of these 3 units: Rei, Modernia, and
                especially Asuka. If you lack Max Ammo rolls, using Bunnies is a
                viable strat. Rei also supports Modernia & Asuka well.
              </p>
              <h5>Team #2: Dream Modernia Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This will help Modernia shine. Both Asuka and Rei need to
                activate their respective Burst Skill(s) to achieve peak
                supportive power. Meanwhile, the only Fire unit currently that
                should not use Burst Skill is Modernia. This makes her the most
                ideal third DPS for this team. Modernia does not have too much
                ATK buff her in her kit, so she will definitely benefit from
                Rei's. Modernia also scales very well with Rei's ATK DMG and
                Asuka's Core DMG. Strong for Campaign and Bossing too! You can
                also choose to use RH B1 as an alternative in Campaign, and even
                use Alice over Asuka if you're willing to put your fingers to
                the test.
              </p>
              <p>
                While Crown is the best buffer, the dilution of ATK DMG is a bit
                too high, and she might be better paired with other units. In
                case of this, S. Rosanna would be a viable alternative, due to
                providing an undiluted Damage Taken debuff.
              </p>
              <h5>Team #3: That Dolla/SHelm Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="soda" enablePopover />
                    <NikkeCharacter mode="icon" slug="pepper" enablePopover />
                    <NikkeCharacter mode="icon" slug="zwei" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="bay-goddess-mary"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A healer with a B2 CDR is some standard P4-P5 configuration.
                When against Iron-weak bosses, using SHelm in this team might
                prove feasible. Pepper can continuously heal the team and
                activate Asuka's ATK buff, whereas Zwei can repair cover and
                buff Asuka (with Pierce DMG). SMary is also an alternative.
                Still, Soda as a Fire MG Unit might be the better choice here
                since she benefits from Asuka's & Rei's supportive buffs. She
                also heals the team and triggers Asuka's ATK buff!
              </p>
              <p>
                However, you will mainly use this team for bosses whose element
                are not Wind and where you need to break Elemental QTE.
              </p>
              <h5>Team #4: More Healing Please</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rem" enablePopover />
                    <NikkeCharacter mode="icon" slug="marciana" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                An alternative to the Summer Rosanna variant if more healing is
                needed and if Dolla/SHelm are worse options. Mainly for bosses
                whose element are not Wind and where you need to break Elemental
                QTE.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="PvP Team Compositions" />
              <h5>A Fistful of Nuke</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                A basic nuking team featuring Rei. Nothing special here. Most of
                you have already understood the thought process behind Jackal +
                XAnne/Blanc teams. Good for Attacking, not so much for Defending
                but usable. Here is a showcase of this team.
                https://x.com/USANIKU_NIKU/status/1828926135679496456?t=DAIK1Nr0bP5r-ecWsqc09w
              </p>
              <h5>Crucify Her</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">ClipSG</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rosanna" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">ClipSG</div>
                </div>
              </div>
              <p>
                The typical Sacrifice P1 team that utilizes Rosanna's S2 to
                charge a massive amount of Burst Energy instantly through the
                death of opposing and friendly allies. In this example, Rei is
                sacrificed, and XAnne revives her. After that, Rei gains ATK
                buff from S2 and ATK DMG from Burst Skill before purging those
                who challenge her.
              </p>
            </div>
            <div className="section-scroll" id="section-7">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Precious supportive buffs for Fire Code allies.</li>
                        <li>
                          Nuke helps clear projectiles and mobs in PvE game
                          modes.
                        </li>
                        <li>
                          Outrageous nuke percentage (%) values with ATK & ATK
                          DMG.
                        </li>
                        <li>
                          Shields Fire Code allies with Individual Shield; can
                          trigger Asuka's Elemental DMG effect.
                        </li>
                        <li>
                          Being an Attacker means her caster's ATK buffs is
                          received well by Fire Nikke(s) from all roles.
                        </li>
                        <li>Built-in Elemental DMG and shieldbreak bonuses.</li>
                        <li>Scaling is good with all kinds of buffs.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Limited collab character. Now or never. Can't get
                          copies in the future.
                        </li>
                        <li>
                          The supportive buffs are only for Fire Code allies and
                          more impactful for some DPS more than others.
                        </li>
                        <li>
                          Personal DPS is not astonishing when wipe is not in
                          play. Better than Scarlet but that is still fairly low
                          on today's standards.
                        </li>
                        <li>
                          MG has low burst generation (more apparent in PvP).
                          Also feeds into Scarlet & Jackal there.
                        </li>
                        <li>
                          Needs Max Ammo OL or Reload Speed buffers, especially
                          when against Wind enemies, where you want maximum
                          Elemental DMG uptime.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Synergy with others</a>
                </li>
                <li>
                  <a href="#section-3">Tier List</a>
                </li>
                <li>
                  <a href="#section-4">Investments</a>
                </li>
                <li>
                  <a href="#section-5">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-7">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRei;

export const Head: React.FC = () => (
  <Seo
    title="Rei Ayanami Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Rei Ayanami in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
